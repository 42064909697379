<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.id"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 850"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div class="text-h6 py-1">
        <v-icon left>
          fal fa-edit
        </v-icon>
        {{ data?.id ? 'Edit' : 'Create' }} Instruction
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-container>
      <v-textarea
        v-for="language in $languages"
        :key="language.value"
        v-model="data.instruction[language.value]"
        :label="language.text"
        class="text-capitalize"
        auto-grow
        dense
        outlined
        :spellcheck="language.value == 'en'"
        :lang="language.value"
      >
        <template
          v-if="language.value !== 'en' && data.instruction.en"
          #append
        >
          <v-btn
            icon
            @click="translateText(data.instruction.en, language.value, v => $set(data.instruction, language.value, v))"
          >
            <v-icon small>
              fa fa-language
            </v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-container>
    <template
      #append
    >
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateInstruction"
        >
          {{ $t('Update') }} Instruction
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({
          instruction: {
            en: '',
          },
        }),
      },
    },
    data: () => ({
      valid: false,
      loading: false,
    }),
    watch: {
      data: {
        deep: true,
        handler (data) {
          if (!data.instruction) {
            this.$set(data, 'instruction', { en: '' })
          }
        },
      },
    },
    methods: {
      updateInstruction () {
        this.loading = true
        this.axios.request({
          method: this.data.id ? 'patch' : 'post',
          url: this.data.id ? 'admin/instructions/' + this.data.id : 'admin/instructions',
          data: this.data,
        })
          .then(() => {
            this.$root.$emit('fetch-instructions')
            this.$emit('close-drawer')
            this.$toast.success('Instruction updated')
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      deleteInstruction () {
        this.$dialog.warning({
          text: 'Do you really want to delete this instruction?',
          title: 'Delete Instruction',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete('admin/instructions/' + this.data.id)
                .then(() => {
                  const index = this.instructions.findIndex(x => x.id === this.data.id)
                  this.$delete(this.instructions, index)
                  this.$toast.success('Instruction deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
<style xscoped>
  .v-text-field__slot textarea {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 0.9em;
    line-height: 1.6em;
  }
</style>
